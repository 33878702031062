/* header */
.header {
	background: #0F2151;
	padding:17px 0;

	.container {
		gap:50px;
		align-items: center;
	}

	
}

/* hamburger */
.hamburger {
	@include sprite(24px,24px,"../img/sprite.svg#sprite--menu-burger");
}
