/* navigation mob */
.navigation {
	&-mobile {
		position: fixed;
		z-index: 100;
		width:0;
		height:100%;
		right:0;
		bottom:0;
		top:0;
		background:#fff;
		border:none;

		&.open {
			width:320px;
		}
	}

	&-heading {
		border-bottom:1px solid #ECECF4;
		align-items: center;
		gap:20px;
		padding:15px;
	}

	&-title {
		font-size: 15px;
		font-weight: 600;
		line-height: 18px;
		color: #16161d;
		text-transform: uppercase;
	}

	&-close {
		@include sprite(18px,18px,"../img/sprite.svg#sprite--close");
	}

	.menu {
		&__item {
			padding:15px;

			&:not(:last-child) {
				border-bottom:1px solid #ECECF4;
			}
		}

		&-link {
			font-size: 15px;
			font-weight: 600;
			line-height: 18px;
			color: #707081;
			text-transform: uppercase;

			&:hover {
				color: #222c6c;
			}
		}
	}

	@media screen and (min-width:1367px) {
		&-mobile {
			&.open {
				width:500px;
			}
		}
	}

	@media screen and (max-width:450px) {
		&-mobile {
			&.open {
				width:100%;
			}
		}
	}
}