/* offer */
.offer {
	&__item {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}

.card {
	gap:0 30px;
	border-radius:25px;
	padding:25px;

	&:not(.card_big) {
		box-shadow:0 4px 20px 0 rgba(#1D1E27, .1);
		align-items: center;

		.card-name {
			color: #16161d;
		}

		.card-rating {
			width:220px;
			min-width:220px;
		}

		.card-bonus {
			flex:1;
		}

		.bonus {
			color: #16161d;
		}

		.form-btn {
			margin-left: auto;
		}
	}

	.img {
		width:160px;
		height:120px;
	}

	&-heading {
		justify-content: flex-start;
		align-items: center;
		gap:10px;
	}

	&-number {
		width:34px;
		height:34px;
		line-height: 34px;
		text-align: center;
		font-weight: 700;
		font-size: 16px;
		color: #222c6c;
		border-radius:10px;
		background: #F0F2FF;
	}

	&-name {
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
	}

	&-title {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		color: #222c6c;
		margin-bottom: 10px;
	}

	ul {
		margin-bottom: 20px;
	}

	.bonus {
		font-weight: 700;
		font-size: 26px;
		line-height: 35px;
	}

	&_big {
		background: #0F2151;
		color:#fff;

		.card-title {
			color:rgba(#fff,.7);
		}

		.card-number {
			background: rgba(#fff,.05);
			color:#fff;
		}

		.rating {
			margin-left: auto;

			&-total {
				color:#fff;
			}
		}

		.btn {
			font-size: 22px;
		}
	}

	&-body {
		flex:1;
	}

	@media screen and (min-width:993px) {
		&:not(.card_big) {
			.card-heading {
				margin-bottom: 10px;
			}
		}

		&_big {
			border-radius:35px;
			padding:35px;

			.img {
				width:300px;
				min-width:300px;
				height:300px;
			}

			.card-heading {
				margin-bottom: 15px;
			}

			.card-number {
				width:40px;
				height:40px;
				line-height: 40px;
				border-radius:15px;
			}

			.card-name {
				font-size: 20px;
				line-height: 24px;
			}

			.card-title {
				font-size: 18px;
				line-height: 20px;
			}

			.bonus {
				font-size: 32px;
				line-height: 39px;
				margin-bottom: 15px;
			}

			.btn {
				width:300px;
				height:61px;
			}
		}
	}

	@media screen and (max-width:992px) {
		justify-content: center;
		text-align: center;

		.img {
			width:290px;
			height:120px;
			margin-bottom: 30px;
		}

		&-body {
			min-width:100%;
		}

		&:not(.card_big) {
			flex-direction:column;

			.card-rating {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap:nowrap;
				gap:5px;
				min-width:100%;
				width:100%;
				margin-bottom: 15px;
			}

			.bonus {
				margin-bottom: 20px;
			}

			.form-btn {
				margin-left: 0;
			}
		}

		&_big {
			.card-heading {
				flex-wrap:nowrap;
				margin-bottom: 15px;
			}

			.bonus {
				margin-bottom: 15px;
			}
		}
	}

	@media screen and (max-width:768px) {
		padding:15px;
	}

	@media screen and (max-width:450px) {
		.btn, .form-btn {
			width:100%;
		}
	}
}