/* =========================== all */
body {
	font-family: "Inter", sans-serif;
	color: #707081;
	background: #fbfcfd;
	line-height: 1.5;
}

.body_scroll {
	overflow: hidden;
}

/* heading */
h1,.h1 {
	font-weight: 700;
	font-size: 36px;
	line-height: 44px;
	color: #16161D;
	margin-bottom: 15px;

	@media screen and (max-width: 768px) {
		font-size: 24px;
		line-height: 30px;
	}
}


/* list */
ul:not([class]){
	li {
		color:#D0D0D6;
		font-size: 18px;
		font-weight: 400;
		line-height: 26px;

		&:not(:last-child) {
			margin-bottom: 2px;
		}
	}
	
}

/* content */
a, p {
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
}

p {
	margin-bottom:15px;
}

.text {
	p {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

a:not([class]) {
	text-decoration: none;

	&:hover {
		color: #222C6C;
	}
}

b, strong {
	font-weight:bold;
}

i, em {
	font-style:italic;
}

/* img */
img {
	display:block;
	margin:0 auto;
	margin-bottom:20px;
}

.img {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius:25px;
	padding:10px;

	img {
		max-width:200px;
		max-height:100px;
		margin:0;
	}
}

/* transition */
* {
	transition:all .3s;
}

/* btn */
.btn {
	position: relative;
	z-index:1;
	width:240px;
	height:58px;
	line-height: 58px;
	border-radius:30px;
	cursor:pointer;
	text-align: center;
	text-decoration: none;
	background: #FFCA00;
	font-size: 20px;
	font-weight: 700;
	color: #16161d;

	&:hover {
		background: #FFE500;
	}
}

/* flexbox */
.flexbox {
	display: flex;
	flex-wrap:wrap;
	justify-content: space-between;
}

/* gridbox */
.gridbox {
	display: grid;
	justify-content: space-between;
}

/* soc */
.rating {
	gap:5px;
	align-items: center;
	justify-content: flex-start;

	&-star {
		@include sprite(22px,22px,"../img/sprite.svg#sprite--star");
	}

	&-total {
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		color: #16161d;
	}
}

/* scroll vertical */
.scroll {
	overflow-y: auto;
	-ms-scroll-chaining: none;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scrollbar-width: thin;
	scrollbar-color: #DCDFE9 transparent;
}

.scroll::-webkit-scrollbar {
	width: 4px;
	height: 50%;
}

.scroll::-webkit-scrollbar-track {
	background-color: transparent; 
}

.scroll::-webkit-scrollbar-thumb {
	background-color: #DCDFE9;
	-webkit-border-radius: 5px;
	border-radius: 5px; 
}

.scroll::-webkit-scrollbar-thumb:hover {
	background-color: #DCDFE9; 
}

.scroll::-webkit-scrollbar-thumb:active {
	background-color: #DCDFE9; 
}

.scroll::-webkit-scrollbar-thumb:vertical {
	min-height: 50%; 
}

.scroll::-webkit-scrollbar-thumb:horizontal {
	min-width: 4px; 
}

/* scroll horizontal */
.scroll_horizontal {
	overflow-x: auto;
	-ms-scroll-chaining: none;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	scrollbar-width: thin;
	scrollbar-color: #DCDFE9 transparent;
}

.scroll_horizontal::-webkit-scrollbar {
	width: 50%;
	height: 4px; 
}

.scroll_horizontal::-webkit-scrollbar-track {
	background-color: transparent; 
}

.scroll_horizontal::-webkit-scrollbar-thumb {
	background-color: #DCDFE9;
	-webkit-border-radius: 5px;
	border-radius: 5px; 
}

.scroll_horizontal::-webkit-scrollbar-thumb:hover {
	background-color: #DCDFE9; 
}

.scroll_horizontal::-webkit-scrollbar-thumb:active {
	background-color: #DCDFE9; 
}

.scroll_horizontal::-webkit-scrollbar-thumb:horizontal {
	min-width: 50%; 
}

.scroll_horizontal::-webkit-scrollbar-thumb:vertical {
	min-height: 4px; 
}

button, input, textarea {
	font-family: "Inter";
}

input, textarea {
	@include placeholder {
		color: #6F6F7D;
		opacity: 1;
		font-family: "Figtree";
		font-size:16px;
		font-weight: 500;
	}
}

input[type=text]::-ms-clear {  
	display: none; width : 0; height: 0; 
}

input[type=text]::-ms-reveal {  
	display: none; width : 0; height: 0; 
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { 
	display: none; 
	-webkit-appearance:none;
	appearance:none;
}

/* swiper slider */
.swiper {
	position: relative;

	&-wrapper {
		display: flex;
	}

	&-slide {
		height:auto;
		width:auto;
	}
}

/* container */
.container {
	max-width:1206px;
	padding:0 20px;
	margin:0 auto;
}

main {
	padding:30px 0 20px;

	header {
		time {
			display: inline-block;
			font-size: 14px;
			font-weight: 500;
			line-height: 17px;
			color: #707081;
			margin-bottom: 15px;
		}
	}

	@media screen and (max-width:768px) {
		padding:20px 0;
	}
}

.logo {
	img {
		margin-bottom: 0;
	}
}