/* =========================== all */
body {
  font-family: "Inter", sans-serif;
  color: #707081;
  background: #fbfcfd;
  line-height: 1.5;
}

.body_scroll {
  overflow: hidden;
}

/* heading */
h1, .h1 {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #16161D;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  h1, .h1 {
    font-size: 24px;
    line-height: 30px;
  }
}

/* list */
ul:not([class]) li {
  color: #D0D0D6;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}
ul:not([class]) li:not(:last-child) {
  margin-bottom: 2px;
}

/* content */
a, p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

p {
  margin-bottom: 15px;
}

.text p:last-child {
  margin-bottom: 0;
}

a:not([class]) {
  text-decoration: none;
}
a:not([class]):hover {
  color: #222C6C;
}

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

/* img */
img {
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.img {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  padding: 10px;
}
.img img {
  max-width: 200px;
  max-height: 100px;
  margin: 0;
}

/* transition */
* {
  transition: all 0.3s;
}

/* btn */
.btn {
  position: relative;
  z-index: 1;
  width: 240px;
  height: 58px;
  line-height: 58px;
  border-radius: 30px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  background: #FFCA00;
  font-size: 20px;
  font-weight: 700;
  color: #16161d;
}
.btn:hover {
  background: #FFE500;
}

/* flexbox */
.flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* gridbox */
.gridbox {
  display: grid;
  justify-content: space-between;
}

/* soc */
.rating {
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
}
.rating-star {
  width: 22px;
  height: 22px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--star");
}
.rating-total {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #16161d;
}

/* scroll vertical */
.scroll {
  overflow-y: auto;
  -ms-scroll-chaining: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: #DCDFE9 transparent;
}

.scroll::-webkit-scrollbar {
  width: 4px;
  height: 50%;
}

.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #DCDFE9;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #DCDFE9;
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #DCDFE9;
}

.scroll::-webkit-scrollbar-thumb:vertical {
  min-height: 50%;
}

.scroll::-webkit-scrollbar-thumb:horizontal {
  min-width: 4px;
}

/* scroll horizontal */
.scroll_horizontal {
  overflow-x: auto;
  -ms-scroll-chaining: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: #DCDFE9 transparent;
}

.scroll_horizontal::-webkit-scrollbar {
  width: 50%;
  height: 4px;
}

.scroll_horizontal::-webkit-scrollbar-track {
  background-color: transparent;
}

.scroll_horizontal::-webkit-scrollbar-thumb {
  background-color: #DCDFE9;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.scroll_horizontal::-webkit-scrollbar-thumb:hover {
  background-color: #DCDFE9;
}

.scroll_horizontal::-webkit-scrollbar-thumb:active {
  background-color: #DCDFE9;
}

.scroll_horizontal::-webkit-scrollbar-thumb:horizontal {
  min-width: 50%;
}

.scroll_horizontal::-webkit-scrollbar-thumb:vertical {
  min-height: 4px;
}

button, input, textarea {
  font-family: "Inter";
}

input::placeholder, textarea::placeholder {
  color: #6F6F7D;
  opacity: 1;
  font-family: "Figtree";
  font-size: 16px;
  font-weight: 500;
}
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #6F6F7D;
  opacity: 1;
  font-family: "Figtree";
  font-size: 16px;
  font-weight: 500;
}
input:-moz-placeholder, textarea:-moz-placeholder {
  color: #6F6F7D;
  opacity: 1;
  font-family: "Figtree";
  font-size: 16px;
  font-weight: 500;
}
input::-moz-placeholder, textarea::-moz-placeholder {
  color: #6F6F7D;
  opacity: 1;
  font-family: "Figtree";
  font-size: 16px;
  font-weight: 500;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #6F6F7D;
  opacity: 1;
  font-family: "Figtree";
  font-size: 16px;
  font-weight: 500;
}

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

/* swiper slider */
.swiper {
  position: relative;
}
.swiper-wrapper {
  display: flex;
}
.swiper-slide {
  height: auto;
  width: auto;
}

/* container */
.container {
  max-width: 1206px;
  padding: 0 20px;
  margin: 0 auto;
}

main {
  padding: 30px 0 20px;
}
main header time {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #707081;
  margin-bottom: 15px;
}
@media screen and (max-width: 768px) {
  main {
    padding: 20px 0;
  }
}

.logo img {
  margin-bottom: 0;
}

/* header */
.header {
  background: #0F2151;
  padding: 17px 0;
}
.header .container {
  gap: 50px;
  align-items: center;
}

/* hamburger */
.hamburger {
  width: 24px;
  height: 24px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--menu-burger");
}

/* navigation mob */
.navigation-mobile {
  position: fixed;
  z-index: 100;
  width: 0;
  height: 100%;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  border: none;
}
.navigation-mobile.open {
  width: 320px;
}
.navigation-heading {
  border-bottom: 1px solid #ECECF4;
  align-items: center;
  gap: 20px;
  padding: 15px;
}
.navigation-title {
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: #16161d;
  text-transform: uppercase;
}
.navigation-close {
  width: 18px;
  height: 18px;
  background: center center/cover no-repeat;
  background-image: url("../img/sprite.svg#sprite--close");
}
.navigation .menu__item {
  padding: 15px;
}
.navigation .menu__item:not(:last-child) {
  border-bottom: 1px solid #ECECF4;
}
.navigation .menu-link {
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: #707081;
  text-transform: uppercase;
}
.navigation .menu-link:hover {
  color: #222c6c;
}
@media screen and (min-width: 1367px) {
  .navigation-mobile.open {
    width: 500px;
  }
}
@media screen and (max-width: 450px) {
  .navigation-mobile.open {
    width: 100%;
  }
}

/* tab */
.tab-menu {
  margin-bottom: 20px;
}
.tab-menu__item {
  display: inline-block;
  border-radius: 21px;
  border: 1px solid #ECECF4;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: #707081;
  text-transform: uppercase;
  padding: 10px 15px;
}
.tab-menu__item[aria-selected=true] {
  background: #F0F2FF;
  border-color: #F0F2FF;
  color: #222C6C;
}
.tab-menu__item:hover {
  border-color: #0F2151;
  color: #0F2151;
}
.tab-container__item {
  display: none;
}
.tab-container__item.active {
  display: block;
}
@media screen and (max-width: 1200px) {
  .tab-menu {
    width: calc(100% + 20px);
    margin-right: -20px;
  }
}

/* offer */
.offer__item:not(:last-child) {
  margin-bottom: 20px;
}

.card {
  gap: 0 30px;
  border-radius: 25px;
  padding: 25px;
}
.card:not(.card_big) {
  box-shadow: 0 4px 20px 0 rgba(29, 30, 39, 0.1);
  align-items: center;
}
.card:not(.card_big) .card-name {
  color: #16161d;
}
.card:not(.card_big) .card-rating {
  width: 220px;
  min-width: 220px;
}
.card:not(.card_big) .card-bonus {
  flex: 1;
}
.card:not(.card_big) .bonus {
  color: #16161d;
}
.card:not(.card_big) .form-btn {
  margin-left: auto;
}
.card .img {
  width: 160px;
  height: 120px;
}
.card-heading {
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.card-number {
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: #222c6c;
  border-radius: 10px;
  background: #F0F2FF;
}
.card-name {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}
.card-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #222c6c;
  margin-bottom: 10px;
}
.card ul {
  margin-bottom: 20px;
}
.card .bonus {
  font-weight: 700;
  font-size: 26px;
  line-height: 35px;
}
.card_big {
  background: #0F2151;
  color: #fff;
}
.card_big .card-title {
  color: rgba(255, 255, 255, 0.7);
}
.card_big .card-number {
  background: rgba(255, 255, 255, 0.05);
  color: #fff;
}
.card_big .rating {
  margin-left: auto;
}
.card_big .rating-total {
  color: #fff;
}
.card_big .btn {
  font-size: 22px;
}
.card-body {
  flex: 1;
}
@media screen and (min-width: 993px) {
  .card:not(.card_big) .card-heading {
    margin-bottom: 10px;
  }
  .card_big {
    border-radius: 35px;
    padding: 35px;
  }
  .card_big .img {
    width: 300px;
    min-width: 300px;
    height: 300px;
  }
  .card_big .card-heading {
    margin-bottom: 15px;
  }
  .card_big .card-number {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 15px;
  }
  .card_big .card-name {
    font-size: 20px;
    line-height: 24px;
  }
  .card_big .card-title {
    font-size: 18px;
    line-height: 20px;
  }
  .card_big .bonus {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 15px;
  }
  .card_big .btn {
    width: 300px;
    height: 61px;
  }
}
@media screen and (max-width: 992px) {
  .card {
    justify-content: center;
    text-align: center;
  }
  .card .img {
    width: 290px;
    height: 120px;
    margin-bottom: 30px;
  }
  .card-body {
    min-width: 100%;
  }
  .card:not(.card_big) {
    flex-direction: column;
  }
  .card:not(.card_big) .card-rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 5px;
    min-width: 100%;
    width: 100%;
    margin-bottom: 15px;
  }
  .card:not(.card_big) .bonus {
    margin-bottom: 20px;
  }
  .card:not(.card_big) .form-btn {
    margin-left: 0;
  }
  .card_big .card-heading {
    flex-wrap: nowrap;
    margin-bottom: 15px;
  }
  .card_big .bonus {
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 768px) {
  .card {
    padding: 15px;
  }
}
@media screen and (max-width: 450px) {
  .card .btn, .card .form-btn {
    width: 100%;
  }
}

/* footer */
.footer {
  padding: 30px 0;
}
@media screen and (max-width: 768px) {
  .footer {
    text-align: center;
  }
}