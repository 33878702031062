/* tab */
.tab {
	&-menu {
		margin-bottom: 20px;
		
		&__item {
			display: inline-block;
			border-radius:21px;
			border:1px solid #ECECF4;
			text-align: center;
			font-size: 15px;
			font-weight: 600;
			line-height: 18px;
			color: #707081;
			text-transform: uppercase;
			padding:10px 15px;

			&[aria-selected="true"] {
				background: #F0F2FF;
				border-color:#F0F2FF;
				color:#222C6C;
			}

			&:hover {
				border-color:#0F2151;
				color:#0F2151;
			}
		}
	}

	&-container {
		&__item {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	@media screen and (max-width:1200px) {
		&-menu {
			width:calc(100% + 20px);
			margin-right: -20px;
		}
	}
}